import { InputSwitch } from '@/components/form/inputs/InputSwitch';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader, IFormInputSwitch } from '@/typings';
import { PropType } from 'vue';

interface IProps {
  value?: boolean;
  input: IFormInputSwitch;
  header: IDataTableHeader;
}

interface IEvents {
  onInput: (value: boolean | null) => void;
}

export const TdSwitch = createComponent<IProps, IEvents>({
  name: 'TdSwitch',
  props: {
    value: { type: Boolean, default: false },
    input: { type: Object as PropType<IFormInputSwitch>, required: true },
    header: { type: Object as PropType<IDataTableHeader>, required: true },
  },
  setup(props, ctx) {
    function emitInput(value: boolean | null) {
      ctx.emit('input', value);
    }

    return () => (
      <div class="flex justify-center items-center" key={props.header.value}>
        <InputSwitch
          input={props.input}
          value={props.value}
          onInput={emitInput}
        />
      </div>
    );
  },
});
