import { ConstantsHelper } from '@/lib/constants/helper';
import {
  IEquipeTipoAcessoLabel,
  IUserFragment,
  IUserFragment_clinicas,
  UserEquipeTipoAcesso,
} from '@/typings';

export const getUserEspecialidade = (v: IUserFragment) =>
  ConstantsHelper.tiss.cbo.find(f => f.value === v.cbo)?.label ||
  'Profissional de saúde';

export const getUserClinica = (
  v: IUserFragment,
  clinicaId: string,
): IUserFragment_clinicas | undefined => {
  const userClinicas = v.clinicas || [];

  return userClinicas.find(f => f.clinica.id === clinicaId);
};

export const getUserTipo = (v: IUserFragment) =>
  ConstantsHelper.userTipos.find(f => f.value === v.tipo)?.label;

export const getEquipeTipoAcesso = (
  v: IUserFragment,
  clinicaId: string,
): UserEquipeTipoAcesso => {
  if (v.adminAccount) {
    return UserEquipeTipoAcesso.ADMIN_ACCOUNT;
  }

  const userClinica = getUserClinica(v, clinicaId);

  if (userClinica?.adminClinica) {
    return UserEquipeTipoAcesso.ADMIN_CLINICA;
  }

  return UserEquipeTipoAcesso.NONE;
};

export const getEquipeTipoAcessoLabel = (
  v: IUserFragment,
  clinicaId: string,
): IEquipeTipoAcessoLabel => {
  const tipoAcesso = getEquipeTipoAcesso(v, clinicaId);

  switch (tipoAcesso) {
    case UserEquipeTipoAcesso.ADMIN_ACCOUNT:
      return 'Administrador da Conta';

    case UserEquipeTipoAcesso.ADMIN_CLINICA:
      return 'Administrador da Clínica';

    default:
      return 'Acesso restrito';
  }
};

export const getUserHasOneClinica = (v: IUserFragment, clinicaId: string) => {
  if (!v.clinicas || !v.clinicas.length) {
    return false;
  }

  return v.clinicas.length === 1 && !!getUserClinica(v, clinicaId);
};
