import { EnderecoFields } from '@/components/form/fields/EnderecoFields';
import { FormFields } from '@/components/form/fields/FormFields';
import { TelefonesFields } from '@/components/form/fields/TelefonesFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { uuid } from '@/lib/helpers/uuid';
import { ClinicaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IClinicaFormModel,
  IClinicaFormSchema,
  ITelefoneModel,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {
  perfil?: boolean;
}

interface IEvents extends IFormEvents<IClinicaFormModel>, INomeEvents {}

export const ClinicaForm = createComponent<IProps, IEvents>({
  name: 'ClinicaForm',
  props: {
    perfil: { type: Boolean, default: false },
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const indexRoute = Routes.app.configAccount.clinicas.index;

    const { $clinica, $userIsAdmin } = useAuthInfo();

    const $noDelete = computed(
      () =>
        props.perfil ||
        props.page.id === $clinica.value?.id ||
        !$userIsAdmin.value,
    );

    const { $form, $schema, emitSubmit, emitDelete } = useForm({
      props,
      ctx,
    });

    useNome({
      ctx,
      watchNome: () => $form.value.model.dados.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        noCancel={props.perfil}
        noDelete={$noDelete.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection title="Dados da clínica">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.dados}
            v-model={$form.value.model.dados}
          />
        </PageSection>

        <TelefonesFields
          form={$form.value}
          v-model={$form.value.model.telefones}
        />

        <EnderecoFields
          noPais
          form={$form.value}
          v-model={$form.value.model.endereco}
        />
      </MyForm>
    );
  },
});

function useForm({ props, ctx }: { props: IProps; ctx: SetupContext }) {
  return useFormConfig<IClinicaFormModel, IClinicaFormSchema>({
    page: props.page,
    initialValue: {
      dados: {
        nome: null,
        email: null,
        cnes: null,
      },
      telefones: [],
      endereco: {
        enderecoCep: null,
        enderecoLogradouro: null,
        enderecoNumero: null,
        enderecoComplemento: null,
        enderecoBairro: null,
        enderecoCidade: null,
        enderecoUf: null,
      },
    },
    mapSchema: () => ({
      dados: {
        nome: {
          label: 'Nome',
          type: 'text',
          validations: {
            required: true,
          },
        },
        email: {
          label: 'Email',
          type: 'email',
        },
        cnes: {
          label: 'Código no CNES',
          type: 'text',
          maxLength: 7,
        },
      },
    }),
    ctx,
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await ClinicaService.getById(id);

      if (!editValue) return redirectError(404);

      const telefones: ITelefoneModel[] = (editValue.telefones || []).map(
        numero => ({
          id: uuid(),
          numero,
        }),
      );

      setFormModel({
        dados: {
          nome: editValue.nome,
          email: editValue.email,
          cnes: editValue.cnes,
        },
        telefones,
        endereco: {
          enderecoCep: editValue.enderecoCep,
          enderecoLogradouro: editValue.enderecoLogradouro,
          enderecoNumero: editValue.enderecoNumero,
          enderecoComplemento: editValue.enderecoComplemento,
          enderecoBairro: editValue.enderecoBairro,
          enderecoCidade: editValue.enderecoCidade,
          enderecoUf: editValue.enderecoUf,
        },
      });
    },
  });
}
